<template>
  <router-link
    :to="!disabled ? url : ''"
    class="whitespace-nowrap text-center flex items-center flex-col justify-center font-medium rounded border transition-all"
    :class="[buttonSize, buttonType, { disabled: disabled }]"
    v-if="isInternal"
  >
    {{ buttonText }}
  </router-link>
  <a
    :href="!disabled ? url : ''"
    target="_blank"
    class="text-center whitespace-nowrap flex items-center flex-col justify-center font-medium rounded border transition-all"
    :class="[buttonSize, buttonType, { disabled: disabled }]"
    v-else
  >
    {{ buttonText }}</a
  >
</template>
<script>
export default {
  props: {
    buttonText: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'primary'
    },
    url: {
      required: true
    },
    linkType: {
      type: String
    },
    size: {
      type: String,
      default: 'regular'
    }
  },
  data: () => ({
    buttonClass: {
      primary: 'bg-primary text-white border-primary hover:bg-primary hover:border-primary',
      secondary: 'bg-white border-primary text-primary',
      tertiary: ''
    },
    buttonCLassSize: {
      full: 'w-full py-2 px-6 text-base',
      regular: 'py-2 px-6 text-base',
      small: 'py-1 px-3 text-sm'
    }
  }),
  computed: {
    buttonType() {
      return this.buttonClass[this.type]
    },
    buttonSize() {
      return this.buttonCLassSize[this.size]
    },
    isInternal() {
      return this.linkType === 'internal'
    }
  }
}
</script>
<style lang="scss" scoped>
.disabled {
  background-color: #d6d6d6;
  color: #fff;
  border-color: #d6d6d6;
  cursor: not-allowed;
}
</style>
